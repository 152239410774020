import React from 'react'
import { render } from 'react-dom'
import GenericCarousel from './GenericCarousel'

document.addEventListener('DOMContentLoaded', () => {
  const items = App.State.premiumCars
  render(
    <GenericCarousel
      items={items}
      title={I18n.t('carrousel_title.premium_cars')}
      exploreUrl={`/${I18n.t('routes.cars')}`}
      exploreText={I18n.t('explore_cars_carousel')}
      isPremium
    />,
    document.getElementById('premium-cars')
  )
})
